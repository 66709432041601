import React from 'react';
import Layout from '../../components/Layout';
import ProjectHeader from '../../components/ProjectHeader';
import BrotladenIntro from '../../components/Brotladen/BrotladenIntro'
import BrotladenContent from '../../components/Brotladen/BrotladenContent'
import Footer from '../../components/Footer';

const IndexPage = () => (
  <Layout>
    <ProjectHeader />
    <BrotladenIntro />
    <BrotladenContent />
    <Footer />
  </Layout>
);

export default IndexPage;
