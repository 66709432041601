import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image, Box } from 'rebass';
import { SectionLink } from 'react-scroll-section';
import styled from 'styled-components';
import MouseIcon from '../MouseIcon';
import { Hero, Subheading } from '../ProjectHeading';

const Banner = styled(Image)`
  margin-bottom: -90px;

  @media only screen and (max-width: 700px) {
    margin-bottom: -40px;
  }
`;

const HeaderBox = styled(Box)`
  position: absolute;
  font-style: italic;

  transform: rotate(-10deg);

  @media only screen and (max-width: 700px) {
  }
`;

const BrotladenIntro = () => (
  <StaticQuery
    query={graphql`
      query BrotladenIntro {
        contentfulBrotladen {
          title
          subtitle
          banner {
            file {
              url
            }
          }
        }
      }
    `}
    render={({ contentfulBrotladen }) => {
      return (
        <Fragment>
          <HeaderBox pt={[5, 80, 120]} px={[4, 4, 4]}>
            <Hero
              as="h1"
              color="totalDark"
              fontSize={[5, 6, 8]}
              mb={[2, 3, 4]}
            >
              {contentfulBrotladen.title}
            </Hero>

            <Subheading
              as="h2"
              color="totalDark"
              fontSize={[4, 5, 6]}
              mb={[3, 5]}
            >
              {contentfulBrotladen.subtitle}
            </Subheading>

          </HeaderBox>

          <div>
            <Banner
              mt={[50, 50, -190]}
              src={contentfulBrotladen.banner.file.url}
              width="97%"
              alt="Distant Grounds Application with Processing"
            />
          </div>

          <SectionLink section="Brotladen Content">
            {({ onClick }) => <MouseIcon onClick={onClick} />}
          </SectionLink>
        </Fragment>
      );
    }}
  />
);

export default BrotladenIntro;
