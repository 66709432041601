import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Box, Flex } from 'rebass';
import Fade from 'react-reveal/Fade';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { ProjectContentCard } from '../ProjectContentCard';
import Section from '../Section';
import markdownRenderer from '../MarkdownRenderer';
import SeeProjects from '../../sections/SeeProjects';
import { ProjectImageLeft, ProjectImageRight } from '../ProjectImage';
import ProjectTitle from '../ProjectTitle';

const ProjectVideoRight = styled.div`
  transform-origin: center;
  transform: translate(40px);

  @media only screen and (max-width: 830px) {
    transform: translate(0px) scale(1.2);
    padding-bottom: 0px;
  }
`;

const BrotladenContent = () => (
  <Section.Container id="Brotladen Content">
    <StaticQuery
      query={graphql`
        query BrotladenContent {
          contentfulBrotladen {
            introduction {
              introduction
            }
            challenge {
              challenge
            }
            solution {
              solution
            }
            result {
              result
            }
            contentPictures {
              id
              title
              file {
                url
                fileName
              }
            }
          }
        }
      `}
      render={({ contentfulBrotladen }) => {
        const imgClubbing = contentfulBrotladen.contentPictures.filter(
          object => object.title === 'clubbing',
        );
        const imgUnderYourSkin = contentfulBrotladen.contentPictures.filter(
          object => object.title === 'MockUP UnderYourSkin',
        );
        const imgDanGrassler = contentfulBrotladen.contentPictures.filter(
          object => object.title === 'MockUP DanGrassler',
        );
        const imgWerner = contentfulBrotladen.contentPictures.filter(
          object => object.title === 'MockUP Werner',
        );

        return (
          <Fade bottom>
            <ProjectContentCard>
              <Flex justifyContent="top" flexWrap="wrap">
                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Introduction</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulBrotladen.introduction.introduction}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]} py={[15, 40, 0]}>
                  {imgClubbing.map(img => (
                    <ProjectImageRight src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1  , 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Challenge</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulBrotladen.challenge.challenge}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]} py={[0, 0, 0]} mt={40}>
                  <ProjectVideoRight>
                    <iframe
                      title="Brotladen Dan Grassler"
                      width="100%"
                      height='400'
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D900264656689095&width=500&show_text=false&height=280&appId"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </ProjectVideoRight>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  {imgUnderYourSkin.map(img => (
                    <ProjectImageLeft src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 1 / 2]} py={[60, 100, 0]}>
                  <Box width={[1, 1, 1]} px={[2, 3, 4]}>
                    <Fade bottom>
                      <ProjectTitle>Solution</ProjectTitle>
                      <ReactMarkdown
                        source={contentfulBrotladen.solution.solution}
                        renderers={markdownRenderer}
                      />
                    </Fade>
                  </Box>

                  <Box width={[1, 1, 1]} px={[2, 3, 4]}>
                    <Fade bottom>
                      <ProjectTitle>Result</ProjectTitle>
                      <ReactMarkdown
                        source={contentfulBrotladen.result.result}
                        renderers={markdownRenderer}
                      />
                    </Fade>
                  </Box>

                  <Box width={[1, 1, 1]} py={[0, 0, 0]} mt={40}>
                    <ProjectVideoRight>
                      <iframe
                        title="Brotladen and Distant Grounds"
                        width="100%"
                        height="400"
                        src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F786203808095181%2Fvideos%2F831270596921835%2F%3Fv%3D831270596921835&width=500&show_text=false&height=280&appId"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </ProjectVideoRight>
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]} py={[60, 100, 0]}>
                  {imgWerner.map(img => (
                    <ProjectImageLeft src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[60, 100, 0]}>
                    {imgDanGrassler.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>
                </Box>
              </Flex>
            </ProjectContentCard>

            <SeeProjects />
          </Fade>
        );
      }}
    />
  </Section.Container>
);

export default BrotladenContent;
